<template>
  <div>
    <v-container>
      <v-row>
        <v-col :cols="12">
          <base-card>
            <div slot="heading" class="title font-weight-light">BPO Attachment</div>
            <v-flex xs12>
              <p class="title">1. {{ $t('bpo.select_client') }}</p>
              <v-form ref="form">
                <v-select
                  :items="clientOptions"
                  label="Client Organization"
                  outline
                  :rules="[rules.required]"
                  @change="onClientSelected"
                />

                <p class="title">2. {{ $t('bpo.select_bpo') }}</p>
                <organizations
                  title="BPO一覧"
                  :disabled="loading || !selectedClient"
                  :orgs="bpoOptions"
                  @selected="onBPOSelected"
                />
                <div class="d-flex flex-row-reverse">
                  <v-btn color="teal" dark class="mt-3" @click="save">
                    {{ $t('common.save') }}
                  </v-btn>
                </div>
              </v-form>
            </v-flex>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Organizations from '../components/Organizations.vue'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    Organizations,
    BaseCard,
  },
  data() {
    return {
      selectedClient: null,
      selectedBPOs: [],
      loading: true,
      rules: {
        required: (value) => !!value || 'Required.',
      },
    }
  },
  mounted() {
    const params = {
      perPage: 100,
    }
    Promise.all([this.fetchBPOs(params), this.fetchClients(params)]).then(() => {
      this.loading = false
    })
  },
  computed: {
    clientOptions() {
      return this.clients.map((client) => ({
        text: client.name,
        value: client.id,
      }))
    },
    bpoOptions() {
      return this.bpos.map((bpo) => ({
        ...bpo,
        selected: this.selectedBPOs.includes(bpo.id),
      }))
    },
    ...mapState({
      bpos: (state) => state.bpo.bpos,
      clients: (state) => state.client.clients,
    }),
  },
  methods: {
    save() {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      const payload = {
        client: this.selectedClient,
        bpos: this.selectedBPOs,
      }
      this.attach(payload).then(() => {
        this.$snotify.success(this.$t('common.save_successfully'))
      })
    },
    ...mapActions(['fetchClients', 'fetchBPOs', 'attach', 'fetchClientBPOs']),
    onClientSelected(orgId) {
      this.loading = true
      this.fetchClientBPOs(orgId)
        .then((bpos) => {
          this.selectedBPOs = bpos.map((bpo) => bpo.id)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      this.selectedClient = orgId
    },
    onBPOSelected(orgId) {
      if (this.selectedBPOs.includes(orgId)) {
        this.selectedBPOs = this.selectedBPOs.filter((o) => o !== orgId)
      } else {
        const prev = this.selectedBPOs
        this.selectedBPOs = [...prev, orgId]
      }
    },
  },
}
</script>
