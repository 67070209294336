<template>
  <v-card>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-list>
      <v-list-item
        v-for="(org, index) in orgs"
        :key="index"
        :disabled="disabled"
        @click="onChanged(org.id)"
      >
        <v-list-item-action>
          <v-icon color="blue">
            {{ org.selected ? 'check_box' : 'check_box_outline_blank' }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title v-text="org.name" />
        </v-list-item-content>

        <v-avatar>
          <img src="/abeja.jpg" />
        </v-avatar>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    orgs: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: true },
  },

  methods: {
    onChanged(orgId) {
      this.$emit('selected', orgId)
    },
  },
}
</script>
